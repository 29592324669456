.addStaff_header {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
}
.addStaff_header > input {
  border-radius: 0.3rem;
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 0.3rem 0.8rem;
  outline: none;
  color: #a8a9b4;
  font-size: 0.8rem;
}
.addStaff_header > input:focus {
  outline: none;
  border: 1px solid #1976d2;
}
