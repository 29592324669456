.card_bodyWrapper {
  display: flex;
}
.card_bodyWrapper > div:nth-child(1) {
  flex: 20%;
  display: flex;
  align-items: center;
  margin-right: 1rem;
}
.card_bodyWrapper > div:nth-child(2) {
  flex: 80%;
  /* display: flex;
  flex-direction: column;
  justify-content: center; */
}
.cardBody_items > div > div {
  display: flex;
  gap: 0.5rem;
}
.cardBody_items > div > div > span:nth-child(1) {
  background-color: #edeff1;
  display: block;
  width: 2rem;
  height: 1.5rem;
  border-radius: 0.3rem;
  text-align: center;
  color: #636578;
  margin-right: 0.3rem;
  margin-bottom: 0.5rem;
  font-size: 0.8rem;
  font-weight: bold;
}
.cardBody_items > div > div > span:nth-child(2) {
  color: #636578;
  font-size: 0.8rem;
}
