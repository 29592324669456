.mediumCard_wrapper {
  display: flex;
  flex-direction: column;
  border: 1px solid rgba(0, 0, 0, 0.1);
  height: 100%;
  padding: 0.3rem;
  border-radius: 0.3rem;
  height: 270px;
}
.mediumCard_wrapper > div:nth-child(1) {
  flex: 30%;
}
.mediumCard_wrapper > div:nth-child(1) > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.mediumCard_wrapper > div:nth-child(2) {
  flex: 50%;
}
.mediumCard_wrapper > div:nth-child(3) {
  flex: 20%;
}
.cardMedium_footer > button {
  margin: 1.3rem 0.5rem;
  width: 90px;
  border-radius: 0.3rem;
}
