.cellAction {
  display: flex;
  align-items: center;
  gap: 15px;
}
.cellAction .viewButton {
  padding: 2px 5px;
  border-radius: 5px;
  border: 1px dotted rgba(0, 0, 139, 0.596);
  cursor: pointer;
}
.cellAction .deleteButton {
  padding: 2px 5px;
  border-radius: 5px;
  color: crimson;
  border: 1px dotted rgba(220, 20, 60, 0.6);
  cursor: pointer;
}
.cellAction .editButton {
  padding: 2px 5px;
  border-radius: 5px;
  color: crimson;
  border: 1px dotted rgba(220, 20, 60, 0.6);
  cursor: pointer;
}
