.staffForm_wrapper {
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  background-color: var(--bg-white-dark);

  width: 350px;
  z-index: 20;
  padding: 0.6rem;
  overflow-y: auto;
  overflow-x: hidden;
  transition: all 0.4s ease-in-out;
}
.staffForm_overlay {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: var(--border-light-black);
  z-index: 19;
}
.staffForm_wrapper > div {
  color: var(--text-gray-medium);
  display: flex;
  justify-content: space-between;
  flex: 1;
}
.staffForm_wrapper > div > span {
  cursor: pointer;
}
.staffForm_wrapper > button {
  width: 100%;
  margin: 1rem 0;
}
/* .formBtn {
  background-color: blueviolet;
  color: #fff;
  padding: 0.6rem 0;
  border: none;
  border-radius: 0.4rem;
  transition: all 0.7s ease-in-out;
  text-transform: uppercase;
} */
@media screen and (max-width: 600px) {
  .staffForm_wrapper {
    width: 100%;
  }
}
