.sidebar_wrapper {
  width: 280px;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  overflow-y: auto;
}
.overlay_sidebar {
  background-color: rgba(0, 0, 0, 0.1);
  height: 100vh;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 25;
}
.sidebarSmallScreen_wrapper {
  width: 280px;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  background-color: #ffff;
  z-index: 30;
}

/* .sidebar_wrapper:hover {
  
  } */
.sidebar_wrapper::-webkit-scrollbar,
.sidebarSmallScreen_wrapper::-webkit-scrollbar {
  width: 4px;
}
.sidebar_wrapper::-webkit-scrollbar-track,
.sidebarSmallScreen_wrapper::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.sidebar_wrapper::-webkit-scrollbar-thumb,
.sidebarSmallScreen_wrapper::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.sidebar_wrapper::-webkit-scrollbar-thumb:hover,
.sidebarSmallScreen_wrapper::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.logo_box {
  margin: 0.7rem 0.6rem;
  display: flex;
  align-items: center;
}
.logo_box > span {
  flex: 1;
  text-align: center;
}
