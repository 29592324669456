.year {
  display: flex;
  border-radius: 0.3rem;
  font-size: 0.7rem;
  color: #fff;
  background: #7a6cff;
  align-items: center;
  width: 70px;
  padding-left: 0.2rem;
}
.icon_box {
  position: absolute;
  top: 10px;
  right: 10px;
  height: 100%;
}
.icon_box > p {
  width: 40px;
  height: 40px;
  background: #f04337;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}

.bottom_wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.span_text {
  font-size: 0.7rem;
  color: #cfa7ae;
}

.card_div {
  height: 280px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0.3rem;
  padding: 0.1rem 0.2rem;
}

.card_div {
  display: flex;
  flex-direction: column;
}
.card_div > div:nth-child(1) {
  flex: 30%;
}
.boxOne {
  display: flex;
  flex-direction: column;
  color: #7b7d8d;
}
.boxTwo {
  display: flex;
  flex-direction: column;
}

.boxOne > div:nth-child(1) {
  display: flex;
  flex: 2;
  justify-content: space-between;
  align-items: center;
}
.boxOne > div:nth-child(1) {
  display: flex;
  flex: 2;
  justify-content: space-between;
  align-items: center;
}
.boxTwo > div:nth-child(1) {
  display: flex;
  justify-content: space-between;
  color: #7b7d8d;
}

.boxTwo > div:nth-child(2) {
  display: flex;
  justify-content: space-between;
  color: #7b7d8d;
}

.card_div > div:nth-child(2) {
  flex: 50%;
}
.card_div > div:nth-child(3) {
  flex: 20%;
}
.boxThree {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.boxThree_second {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.boxThree > div {
  width: 80%;
  height: 0.7rem;
  background-color: #666cff;
  border-radius: 3rem;
  position: relative;
}
.boxThree > div::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 30%;
  height: 100%;
  background: #fdb528;
  border-radius: 3rem;
}
.boxThree_second > div {
  width: 80%;
  height: 0.7rem;
  background-color: #666cff;
  border-radius: 3rem;
  position: relative;
}
@media screen and (max-width: 550px) {
  .slide_div {
    background-image: none;
  }
}
