.logobox {
  display: flex;
  align-items: center;
}
.logo_items {
  border-radius: 0.3rem;
  margin-right: 0.4rem;
  margin-bottom: 0.4rem;
  display: flex;
  align-items: center;
  background: #5a5fe0;
  color: #fff6e5;
  width: 35px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.heading {
  color: #ffffff;
  margin-right: 0.3rem;
  font-weight: 400;
  font-size: 0.9rem;
  letter-spacing: 1px;
}
