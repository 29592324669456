.graphCard_wrapper {
  display: flex;
  flex-direction: column;
  border: 1px solid rgba(0, 0, 0, 0.1);
  height: 100%;
  padding: 0.3rem;
  border-radius: 0.3rem;
}
.graphCard_wrapper > div:nth-child(1) {
}
.graphCard_wrapper > div:nth-child(1) > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.bar_graph {
  flex: 1;
  position: relative;
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  align-items: flex-end;
}
/* .bar_graph > span:nth-child(3) {
  background-color: #72e128;
  display: block;
  width: 0.7rem;
  height: 11rem;
  border-radius: 0.5rem;
  position: absolute;
  bottom: 0;
  margin-right: 1rem;
} */
.bar {
  display: block;
  background-color: #72e128;
  width: 1rem;
  border-radius: 0.5rem;
}
.bar1 {
  height: 0.3rem;
}
.bar.bar1 {
  height: 3rem;
}
.bar.bar2 {
  height: 6rem;
}
.bar.bar3 {
  height: 4rem;
}
.bar.bar4 {
  height: 8rem;
}
.bar.bar5 {
  height: 10rem;
}
.bar.bar6 {
  height: 5rem;
}
.bar.bar7 {
  height: 6rem;
}
.bar.bar8 {
  height: 7rem;
}
.bar.bar9 {
  height: 2rem;
}
