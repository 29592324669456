.staffForm_input,
.select_Box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
  margin-bottom: 1.2rem;
  position: relative;
  left: 120%;
  transition: all 0.5s ease-in-out;
}

/* .staffForm_inputs > label {
  position: absolute;
  top: 50%;
  transform: translate(10%, -50%);
} */
.staffForm_input>input {
  width: 100%;
  border-radius: 0.4rem;
  border: none;
  outline: none;
  border: 1px solid var(--border-light-black);
  padding: 0.7rem 0.5rem;
  color: var(--text-gray-light);
}

.staffForm_input>input:focus {
  border: 2px solid var(--border-blue);
}

input[type="date"] {
  color: var(--border-blue);
}

input[type="date"]:not(.has-value):before {
  color: var(--text-gray-light);
  content: attr(placeholder);
  margin-right: 0.5em;
}

/* .selectBox {
  display: flex;
  margin: 1rem 0;
  flex-direction: column;
} */

.staffForm_input>label,
.select_Box>label {
  color: #111;
  margin-bottom: 0.4rem;
}

.staffForm_input>span,
.select_Box>span {
  color: rgb(229, 26, 26);
  font-size: 0.9rem;
  margin-left: 0.5rem;
  margin-top: 0.4rem;
  display: none;
}

input:invalid[focused="true"] {
  border: 1px solid red;
}

input:invalid[focused="true"]~span {
  display: block;
  text-align: start;
}

select:invalid[focused="true"] {
  border: 1px solid red;
}

select:invalid[focused="true"]~span {
  display: block;
}

.select_Box>select {
  width: 100%;
  padding: 0.7rem 0.5rem;
  border-radius: 0.4rem;
  border: none;
  outline: none;
  border: 1px solid var(--border-light-black);
  color: var(--text-gray-light);
}