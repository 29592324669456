.sideButton_wrapper {
  /* padding: 0.3rem; */
  border-radius: 0.4rem;
  cursor: pointer !important;
  /* display: none; */
  /* z-index: 100; */
}
.sideButton_wrapper > a {
  text-decoration: none;
}
.sideButton_wrapper:hover {
  background: #f6f6f7;

  cursor: pointer;
}
.sideButton_content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 1rem;
  margin-bottom: 0.5rem;
  padding: 0.4rem;
}
