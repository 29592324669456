.tableHead_wrapper {
  display: flex;
  flex-direction: column;
}
.tableHead_wrapper > div:first-child {
  flex: 1;
  margin: 1rem 0.8rem;
  color: #8d8f9c;
}
.tableHead_wrapper > div:last-child {
  text-align: end;
  flex: 1;
  padding: 0.5rem 1rem;
}

.tableHead_wrapper > div:last-child > input {
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 0.5rem 1rem;
  border-radius: 0.4rem;
  margin-left: 0.5rem;
  outline: none;
}
.tableHead_wrapper > div:last-child > input:focus {
  border: 1px solid lightgreen;
}
