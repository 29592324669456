.card_wrap {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  display: flex;
  justify-content: space-between;
  padding: 1.2rem;
  border-radius: 0.5rem;
  /* width: 250px !important; */
  margin-bottom: 1rem;
}
.card_wrap > div:nth-child(1) {
  text-align: start;
}
.card_wrap > div:nth-child(2) {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
