.year {
  display: flex;
  border-radius: 0.3rem;
  font-size: 0.7rem;
  color: #fff;
  background: #7a6cff;
  align-items: center;
  width: 70px;
  padding-left: 0.2rem;
}
.icon_box {
  position: absolute;
  top: 10px;
  right: 10px;
  height: 100%;
}
.icon_box > p {
  width: 40px;
  height: 40px;
  background: #f04337;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}

.bottom_wrapper {
  display: flex;
  align-items: center;
  justify-content: start;
}

.card-title_big {
  color: #7c6578;
}
.card_paragraph {
  color: #cfa7ae;
  font-size: small;
}
.card_paragraph > span {
  color: lightgreen;
}

.card_btns > div:nth-child(1) > div > div:nth-child(1) {
  background: #ecedff;
  color: red;
}
.card_btns > div:nth-child(2) > div > div:nth-child(1) {
  color: #fdb528;
  background: #ecedff;
}
.card_btns > div:nth-child(3) > div > div:nth-child(1) {
  color: #26c6f9;
  background: #ecedff;
}
.card_btns > div:nth-child(4) > div > div:nth-child(1) {
  color: blue;
  background: #ecedff;
}
.card_btns > div:nth-child(5) > div > div:nth-child(1) {
  color: orchid;
  background: #ecedff;
}
