.card_topHeader {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.card_topHeader > div > h5 {
  color: #646679;
}
.card_topHeader > div > span {
  color: #72e128;
  font-weight: bold;
  margin-left: 0.5rem;
}
.card_topHeader > p {
  color: #a4a5b0;
  font-size: 0.9rem;
}
.card_topHeader > p > span {
  color: #72e128;
  font-weight: bold;
  margin-left: 0.5rem;
}
