.projects_wrapper {
  border: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  height: 90vh;
}
.projects_wrapper > div:nth-child(1) {
  flex: 1;
}
.projects_wrapper > div:nth-child(2) {
  flex: 3;
}
