.slide_div {
  background: #666cff;
  border-radius: 0.3rem;
  color: #fff;
  background-image: url("../../../images/watch.png");
  background-repeat: no-repeat;
  background-position: right;
  border-radius: 0.3rem;
  margin-bottom: 1rem;
  /* background-size: 100vh; */
  /* height: 280px; */
  /* background-position: center center; */
}

.card-title {
  color: #ffffff !important;
}
.card_subtitle {
  color: #ffffff !important;
  font-size: small;
}
.card_subtitle > span {
  color: lightgreen;
  margin-left: 0.5rem;
}
