.main {
  border: 1px solid rgba(0, 0, 0, 0.1);
  height: auto;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.topBox {
  flex: 1;
  display: flex;
  align-items: center;
  padding: 0 0.4rem;
}

.topBox > div:nth-child(1) {
  flex: 1;
}
.topBox > div:nth-child(2) {
  flex: 2;
}

.contentBox {
  display: flex;
  flex-direction: column;
}
.contentBox > div:nth-child(1) {
  display: flex;
}
.contentBox > div:nth-child(1) > h5 {
  color: #696b7d;
  margin-right: 0.5rem;
}
.contentBox > div:nth-child(1) > span {
  color: red;
  font-weight: thin;
}
.contentBox > div:nth-child(2) {
  display: flex;
  flex-direction: column;
  color: #d1d1d7;
  letter-spacing: 0.5px;
}
