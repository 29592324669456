#admin {
  background-color: lightcoral;
  color: #fff;
  width: max-content;
  padding: 0rem 0.5rem;
  border-radius: 0.5rem;
  margin-left: auto;
  margin-bottom: 1rem;
}
#admin > div > p,
#user > div > p {
  margin: 0;
}
#user {
  text-align: start;
  margin-top: 1rem;
  background-color: blueviolet;
  color: #fff;
  width: max-content;
  padding: 0rem 0.5rem;
  border-radius: 0.5rem;
}
