.form_component > div {
  color: var(--text-gray-medium);
  display: flex;
  justify-content: space-between;
  flex: 1;
  margin-bottom: 1rem;
}

.staffForm_wrapper > button {
  width: 100%;
  margin: 1rem 0;
}
.formBtn {
  background-color: blueviolet;
  color: #fff;
  padding: 0.6rem 0;
  border: none;
  border-radius: 0.4rem;
  transition: all 1.3s ease-in-out;
  text-transform: uppercase;
}
.animation_inputs {
  display: flex;
  flex-direction: column;
}
.animation_inputs > div:nth-child(1) > div {
  transition-delay: 0.3s;
}
.animation_inputs > div:nth-child(2) > div {
  transition-delay: 0.4s;
}
.animation_inputs > div:nth-child(3) > div {
  transition-delay: 0.5s;
}
.animation_inputs > div:nth-child(4) > div {
  transition-delay: 0.6s;
}
.animation_inputs > div:nth-child(5) > div {
  transition-delay: 0.7s;
}
.animation_inputs > div:nth-child(6) > div {
  transition-delay: 0.8s;
}
.animation_inputs > div:nth-child(7) > div {
  transition-delay: 1s;
}
.animation_inputs > div:nth-child(8) > div {
  transition-delay: 1.2s;
}
.animation_inputs > div:nth-child(9) > div {
  transition-delay: 1.4s;
}
.animation_inputs > div:nth-child(10) > div {
  transition-delay: 1.6s;
}

@media screen and (max-width: 600px) {
  .staffForm_wrapper {
    width: 100%;
  }
}
