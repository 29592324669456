.home {
  background-color: #a7bcff;
  height: 87.5vh;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.home .container {
  /* border: 1px solid white;
    border-radius: 10px; */
  width: 70%;
  height: 80%;
  display: flex;
  overflow: hidden;
}

.home .container .sidebar {
  flex: 1;
  background-color: #3e3c61;
  position: relative;
}

.home .container .sidebar .navbar {
  display: flex;
  align-items: center;
  background-color: #2f2d52;
  height: 50px;
  padding: 10px;
  justify-content: space-between;
  color: #ddddf7;
}

.home .container .sidebar .navbar .logo {
  font-weight: bold;
  display: none !important;
}

@media screen and (max-width: 768px) {
  .home .container .sidebar .navbar .logo {
    display: none;
  }
}

.home .container .sidebar .navbar .user {
  display: none;
  gap: 10px;
  margin: 0.5rem 0;
}

.home .container .sidebar .navbar .user img {
  background-color: #ddddf7;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  object-fit: cover;
}

.home .container .sidebar .navbar .user button {
  background-color: #5d5b8d;
  color: #ddddf7;
  font-size: 10px;
  border: none;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .home .container .sidebar .navbar .user button {
    position: absolute;
    bottom: 10px;
  }
}

.home .container .sidebar .search {
  border-bottom: 1px solid gray;
}

.home .container .sidebar .search .searchForm {
  padding: 10px;
}

.home .container .sidebar .search .searchForm input {
  background-color: transparent;
  border: none;
  color: white;
  outline: none;
}

.home .container .sidebar .search .searchForm input::placeholder {
  color: lightgray;
}

.home .container .sidebar .userChat {
  /* padding: 10px; */
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  color: white;
  cursor: pointer;
}

.home .container .sidebar .userChat > div {
  padding: 0.5rem 0.4rem;
}
.home .container .sidebar .userChat > div:hover {
  background-color: #2f2d52;
}

.home .container .sidebar .userChat img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}

.home .container .sidebar .userChat .userChatInfo span {
  font-size: 1rem;
  font-weight: 400;
}

.home .container .sidebar .userChat .userChatInfo p {
  font-size: 14px;
  color: lightgray;
}

.home .container .chat {
  flex: 2;
}

.home .container .chat .chatInfo {
  height: 50px;
  background-color: #5d5b8d;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  color: lightgray;
}

.home .container .chat .chatIcons {
  display: flex;
  gap: 10px;
}

.home .container .chat .chatIcons img {
  height: 24px;
  cursor: pointer;
}

.home .container .chat .messages {
  background-color: #ddddf7;
  padding: 10px;
  height: calc(100% - 100px);

  overflow: auto;
}

.home .container .chat .messages .message {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
}

.home .container .chat .messages .message .messageInfo {
  display: flex;
  flex-direction: column;
  color: gray;
  font-weight: 300;
}

.home .container .chat .messages .message .messageInfo img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  object-fit: cover;
}

.home .container .chat .messages .message .messageContent {
  max-width: 80%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.home .container .chat .messages .message .messageContent p {
  background-color: white;
  padding: 10px 20px;
  border-radius: 0px 10px 10px 10px;
  max-width: max-content;
}

.home .container .chat .messages .message .messageContent img {
  width: 50%;
}

.home .container .chat .messages .message.owner {
  flex-direction: row-reverse;
}

.home .container .chat .messages .message.owner .messageContent {
  align-items: flex-end;
}

.home .container .chat .messages .message.owner .messageContent p {
  background-color: #8da4f1;
  color: white;
  border-radius: 10px 0px 10px 10px;
}

.home .container .chat .input {
  height: 50px;
  background-color: white;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.home .container .chat .input input {
  width: 100%;
  border: none;
  outline: none;
  color: #2f2d52;
  font-size: 18px;
}

.home .container .chat .input input::placeholder {
  color: lightgray;
}

.home .container .chat .input .send {
  display: flex;
  align-items: center;
  gap: 10px;
}

.home .container .chat .input .send img {
  height: 24px;
  cursor: pointer;
}

.home .container .chat .input .send button {
  border: none;
  padding: 10px 15px;
  color: white;
  background-color: #8da4f1;
  cursor: pointer;
}
@media screen and (max-width: 768px) {
  .home .container {
    width: 100%;
  }
}
