#tHeader {
  display: flex;
  /* justify-content: center; */
  flex-direction: row-reverse !important;
  flex-wrap: wrap !important;
  justify-content: space-between;
  align-items: center;
  /* background-color: blueviolet; */
  /* align-items: flex-start !important; */
}
#tHeader > div {
  /* text-align: start; */
  /* background-color: blue; */
  /* flex: 1; */
}
#tHeader > div:nth-child(1) {
  /* display: flex; */
  /* justify-content: flex-start; */
}
.createBtn {
  background-color: rgba(144, 50, 233, 0.155);
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 0.3rem;
  color: #111;
  width: 100px;
  transition: all 0.2s linear;
  margin-left: 1rem;
}
@media screen and (max-width: 700px) {
  .tHeader_content {
    flex: 1;
  }
}
@media screen and (max-width: 600px) {
  #tHeader > div > h4 {
    font-size: 1.2rem;
  }
}
@media screen and (max-width: 550px) {
  #tHeader {
    flex-direction: column !important;
  }
  #tHeader > div:nth-child(1) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 1rem;
  }
  .createBtn {
    width: 80px;
    font-size: 0.8rem;
    padding: 0.5rem 0.7rem;
  }
}
