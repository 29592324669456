.denseGraphCard_wrapper {
  display: flex;
  flex-direction: column;
  border: 1px solid rgba(0, 0, 0, 0.1);
  height: 100%;
  padding: 0.3rem;
  border-radius: 0.3rem;
}

.denseGraphCard_wrapper > div:nth-child(1) > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.denseBar_graph {
  flex: 1;
  position: relative;
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 1rem;
}

.denseGraph_wrapper {
  text-align: center;
}
.denseBarGraph {
  display: block;
  background-color: #666cff;
  width: 1.5rem;
  border-radius: 0.5rem;
  position: relative;
  text-align: center;
}
.denseBarGraph > span {
  /* position: absolute;
  bottom: 0; */
  color: #111;
}
.denseBarGraph:nth-of-type(2n + 1) {
  background-color: #eff0ff;
}

.denseGraph_wrapper > span:nth-child(2) {
  color: #968379;
}

.denseBarGraph.denseBarGraph1 {
  height: 3rem;
}
.denseBarGraph.denseBarGraph2 {
  height: 10rem;
}
.denseBarGraph.denseBarGraph3 {
  height: 4rem;
}
.denseBarGraph.denseBarGraph4 {
  height: 8rem;
}
.denseBarGraph.denseBarGraph5 {
  height: 10rem;
}
.denseBarGraph.denseBarGraph6 {
  height: 5rem;
}
.denseBarGraph.denseBarGraph7 {
  height: 6rem;
}
.denseBarGraph.denseBarGraph8 {
  height: 7rem;
}
.denseBarGraph.denseBarGraph9 {
  height: 2rem;
}
