.logobox {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}
.logo:nth-child(1) {
  background: #ecedff;
  color: blue;
  padding: 0.3rem;
  border-radius: 0.3rem;
  margin-right: 0.5rem;
}

.heading_box {
  color: #7c6578;
  margin-right: 0.3rem;
  font-weight: bold;
  font-size: 1.2rem;
}
.span_text {
  font-size: 0.7rem;
  color: #cfa7ae;
}
