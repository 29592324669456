#staffCard_container {
  margin: 1rem 0.5rem;
}

.staffName_box {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 0.5rem 0;
}

.staffName_box>h5 {
  font-size: 1.3rem;
  color: var(--text-black);
}

.staffName_box>h6 {
  border: 0.3rem;
  color: var(--bg-red-light);
  background-color: var(--bg-pink-light);
  padding: 0.3rem;
}

.staff_tasks {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.staff_tasks>div:nth-child(1),
.staff_tasks>div:nth-child(2) {
  display: flex;
  align-items: center;
  flex: 1;
}

.staff_tasks>div:nth-child(1)>span,
.staff_tasks>div:nth-child(2)>span {
  height: 2.5rem;
  width: 2.5rem;
  background-color: var(--bg-blue-light);
  border-radius: 0.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0.5rem;
  color: var(--border-blue);
}

.staff_tasks>div:nth-child(1)>div,
.staff_tasks>div:nth-child(2)>div {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.staff_tasks>div:nth-child(1)>div>h6,
.staff_tasks>div:nth-child(2)>div>h6 {
  color: var(--text-gray-medium);
  font-size: 1.3rem;
}

.staff_tasks>div:nth-child(1)>div>p,
.staff_tasks>div:nth-child(2)>div>p {
  color: var(--text-gray-light);
}

.staff_cardContent>p>span:nth-child(1) {
  font-size: 0.9rem;
  color: var(--text-gray-medium);
  font-weight: 600;
  margin: 0 0.5rem;
}

.staff_cardContent>p>span:nth-child(2) {
  font-size: 0.8rem;
  color: var(--text-gray-light);
}

.staff_status {
  border: 0.3rem;
  color: var(--bg-red-light) !important;
  background-color: var(--bg-pink-light);
  padding: 0.3rem;
}

.collapse_menu {
  border-top: 1px solid var(--border-light-black);
}