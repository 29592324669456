.datatable {
  height: auto;
  max-height: 600px;
  margin-top: 1rem;
}

.datatable .datatableTitle {
  width: 100%;
  font-size: 24px;
  color: var(--text-gray-medium);
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 900;
}

.datatable .datatableTitle .link {
  text-decoration: none;
  color: green;
  font-size: 16px;
  font-weight: 400;
  border: 1px solid green;
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
}

.datatable .cellWithImg {
  display: flex;
  align-items: center;
}

.datatable .cellWithImg .cellImg {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 20px;
}

.datatable .cellWithStatus {
  padding: 5px;
  border-radius: 5px;
}

.datatable .cellWithStatus.active {
  background-color: rgba(0, 128, 0, 0.05);
  color: green;
}

.datatable .cellWithStatus.pending {
  background-color: rgba(255, 217, 0, 0.05);
  color: goldenrod;
}

.datatable .cellWithStatus.passive {
  background-color: rgba(255, 0, 0, 0.05);
  color: crimson;
}
