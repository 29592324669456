.tab_wrapper {
  display: flex;
  gap: 1rem;

  /* padding: 0 1rem; */
}
.tab_wrapper > button {
  background-color: rgba(144, 50, 233, 0.155);
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 0.3rem;
  color: #111;
  width: 100px;
  transition: all 0.2s linear;
}
@media screen and (max-width: 550px) {
  .tab_wrapper > button {
    width: 80px;
    font-size: 0.8rem;
    padding: 0.5rem 0.7rem;
  }
}
