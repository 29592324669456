.timeline_content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.timeline_content > div > h6 {
  color: #7b7d8d;
  max-width: 350px;
  overflow-y: auto;
  max-height: 200px;
  font-weight: 600;
}
.timeline_content > div > p {
  color: #a4a5b0;
  font-weight: 400;
  font-size: 0.9rem;
}
.day {
  color: #a4a4b2;
  font-size: 0.8rem;
  font-weight: light;
}
.main_text {
  color: #7b7d8d;
}
/* scrollbar */
.timeline_content > div::-webkit-scrollbar {
  width: 4px;
}
.timeline_content > div::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.timeline_content > div::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.timeline_content > div::-webkit-scrollbar-thumb:hover {
  background: #555;
}

@media screen and (max-width: 600px) {
  .timeline_content {
    flex-direction: column;
  }
  .timeline_content > div {
    margin-bottom: 1rem;
  }
  .timeline_content > div > h6 {
    padding-right: 1rem;
  }
}
