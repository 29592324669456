.header_wrapper {
  width: calc(100% - 280px);
  position: fixed;
  top: 0;
  left: 280px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  z-index: 10;
  background-color: #ffffff;
}
.header_wrapper > div:nth-child(1) {
  flex: 2;
}
.header_wrapper > div:nth-child(1) > span {
  font-size: 1rem;
  color: #636578;
  cursor: pointer;
  font-size: 0.8rem;
  letter-spacing: 0.3px;
}
.header_wrapper > div:nth-child(2) {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  /* background: pink; */
}

#notification_wrapper::-webkit-scrollbar {
  width: 4px;
}
#notification_wrapper::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
#notification_wrapper::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
#notification_wrapper::-webkit-scrollbar-thumb:hover {
  background: #555;
}

@media screen and (max-width: 1200px) {
  .header_wrapper {
    width: 100%;
    left: 0;
  }
}
