.root {
  width: calc(100% - 280px);
  position: relative;
  left: 280px;
  top: 4rem;
}
@media screen and (max-width: 1200px) {
  .root {
    width: 100%;
    left: 0;
    top: 5rem;
  }
}
